import { useCoreStore } from '@/store/core'
import { storeToRefs } from 'pinia'
import { TrackingFields } from '../constants/trackingFields'
import { TrackingMessages } from '../constants/trackingMessages'
import { useExtendedi18n } from '../hooks/useExtendedi18n'
import { LocaleCode } from '../submodules/sharedTypes/common/Localization'
import { utilTracking } from '../utils/utilTracking'
import { useUserStore } from '@/store/user'
import { SpNavigationItemType } from '@dev.smartpricing/sp-vue-components'
import { TranslationKeys } from '~/i18n/TranslationKeys'
import SupportArea from '~/components/onboarding/SupportArea.vue'
import { TestIds } from '../constants/TestIds'
import { getSmartPayingNavigationItems } from './smartPayingNavigationItems'

const trackClick = (page: string) => {
	utilTracking.track(TrackingMessages.PAGE_VIEW, { [TrackingFields.PAGE_NAME]: page })
}

export const getMainNavigationItems = () => {
	const mainNavigation: SpNavigationItemType[] = []

	const { isMobile } = useMobileViewport()
	const router = useRouter()
	const { isSmartpricingEnabled, isMarketIntelligenceEnabled } = useCoreStore()
	const { isGhtDemoUser } = storeToRefs(useUserStore())

	let smartpricingItemsBeforeDivider = 0
	let smartpayingItemsAfterDivider = 0

	if (isSmartpricingEnabled) {
		mainNavigation.push(
			{
				id: 'calendar',
				type: 'button',
				closeOnClick: isMobile.value,
				text: useLocale().translate(TranslationKeys.CALENDAR),
				icon: SpCommonIcon.Calendar,
				onClick: () => {
					if (!router.currentRoute.value.path.startsWith('/auto-onboarding')) {
						router.push('/calendar')
						trackClick('Calendar')
					}
				},
				activeCondition: () =>
					router.currentRoute.value.path.startsWith('/calendar') ||
					router.currentRoute.value.path.startsWith('/auto-onboarding'),
				testId: TestIds.NavbarCalendar,
			},
			{
				id: 'dashboard',
				text: useLocale().translate(TranslationKeys.DASHBOARD),
				icon: SpCommonIcon.Dashboard,
				type: 'button',
				closeOnClick: isMobile.value,
				onClick: () => {
					router.push('/dashboard')
					trackClick('Dashboard')
				},

				hide: () => router.currentRoute.value.path.startsWith('/auto-onboarding'),
				disableOnMobile: true,
				activeCondition: () => router.currentRoute.value.path.startsWith('/dashboard'),
				testId: TestIds.NavbarDashboard,
			},
			{
				id: 'chart',
				type: 'button',
				closeOnClick: isMobile.value,
				text: useLocale().translate(TranslationKeys.CHARTS),
				icon: SpCommonIcon.LineChart,
				disableOnMobile: true,
				hide: () => router.currentRoute.value.path.startsWith('/auto-onboarding'),
				onClick: () => {
					router.push('/chart')
					trackClick('Chart page')
				},
				activeCondition: () => router.currentRoute.value.path.startsWith('/chart'),
				testId: TestIds.NavbarCharts,
			},
			{
				id: 'strategies',
				type: 'button',
				closeOnClick: isMobile.value,
				text: useLocale().translate(TranslationKeys.STRATEGIES),
				icon: SpCommonIcon.Slider,
				hide: () => router.currentRoute.value.path.startsWith('/auto-onboarding'),
				onClick: () => {
					router.push('/strategies')
					trackClick('Strategies page')
				},
				activeCondition: () => router.currentRoute.value.path.startsWith('/strategies'),
				testId: TestIds.NavbarStrategies,
			}
		)
	}

	if (isMarketIntelligenceEnabled && !router.currentRoute.value.path.startsWith('/auto-onboarding')) {
		mainNavigation.push({
			id: 'market-intelligence',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys.MARKET_INTELLIGENCE),
			icon: SpCommonIcon.Competitors,
			onClick: () => {
				router.push('/competitors')
				trackClick('Market intelligence')
			},
			activeCondition: () => useRouter().currentRoute.value.path.startsWith('/competitors'),
			testId: TestIds.NavbarMarket,
		})
	}

	if (isSmartpricingEnabled) {
		mainNavigation.push({
			id: 'accommodation',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys['navbar.items.ACCOMMODATION_SETTINGS']),
			icon: SpCommonIcon.Setting,
			disableOnMobile: true,
			onClick: () => {
				useRouter().push('/settings/accommodation')
			},
			activeCondition: () => router.currentRoute.value.path.startsWith('/settings/accommodation'),
			testId: TestIds.NavbarAccomodations,
		})
	}

	smartpricingItemsBeforeDivider = mainNavigation.length

	const items = getSmartPayingNavigationItems()

	mainNavigation.push(...items)

	smartpayingItemsAfterDivider = items.length

	if (smartpricingItemsBeforeDivider > 0 && smartpayingItemsAfterDivider > 0) {
		mainNavigation.splice(smartpricingItemsBeforeDivider, 0, {
			id: 'divider-1',
			type: 'divider',
		})
	}

	return mainNavigation
}

export const getBottomNavigationItems = () => {
	const bottomNavigation: SpNavigationItemType[] = []

	const { isGhtDemoUser } = storeToRefs(useUserStore())
	const pathWhitelist = ['/calendar', '/strategies', '/settings', '/competitors']
	const router = useRouter()
	const { isMobile } = useMobileViewport()

	bottomNavigation.push(
		{
			id: 'make-a-wish',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys['make_a_wish_dialog.navigation.TEXT']),
			icon: SpGeneratedIcon.BasicWish,
			onClick: () => {
				useModalStore().makeAWishBanner = true

				utilTracking.track(TrackingMessages.MAKE_A_WISH_DIALOG_OPEN, {})
			},
		},
		{
			id: 'sidebar-guided-tour-button',
			type: 'button',
			closeOnClick: isMobile.value,
			text: useLocale().translate(TranslationKeys.GUIDED_TOUR),
			icon: SpCommonIcon.HelpCircle,
			disableOnMobile: true,
			onClick: () => trackClick('Help'),
			hide: () =>
				pathWhitelist.every((path) => !useRoute().path.startsWith(path)) ||
				isMobile.value ||
				isGhtDemoUser.value ||
				useRouter().currentRoute.value.path.startsWith('/auto-onboarding'),

			testId: TestIds.NavbarSupportGuided,
		},
		{
			id: 'user',
			type: 'expandable',
			text: useLocale().translate(TranslationKeys.USER),
			icon: SpCommonIcon.UserAlt,
			activeCondition: () =>
				['settings-language', 'settings-notifications', 'settings-billing'].includes(
					router.currentRoute.value.name?.toString() || ''
				),
			onClick: () => {},
			testId: TestIds.NavbarUserDropdown,
			navigationItems: [
				{
					id: 'general',
					type: 'button',
					closeOnClick: isMobile.value,
					text: useLocale().translate(TranslationKeys['navbar.items.GENERAL_SETTINGS']),
					onClick: () => {
						useRouter().push('/settings/language')
					},
					icon: SpCommonIcon.Setting,
					activeCondition: () =>
						router.currentRoute.value.path.startsWith('/settings') &&
						!router.currentRoute.value.path.startsWith('/settings/accommodation'),
					testId: TestIds.NavbarUserSettings,
				},
				{
					id: 'logout',
					icon: SpCommonIcon.Exit,
					text: useLocale().translate(TranslationKeys.LOGOUT),
					onClick: () => {
						trackClick('Logout')
						utilLogout.logout()
					},
					type: 'button',
					closeOnClick: isMobile.value,
					testId: TestIds.NavbarUserLogout,
				},
			],
		}
	)

	return bottomNavigation
}
