<script setup lang="ts">
const emits = defineEmits(['close', 'makeWish'])

const { isMobile } = useMobileViewport()

const { currentLocale } = useLocale()
</script>

<template>
	<div>
		<div class="bg-make-wish-image w-full overflow-hidden rounded-[20px]">
			<!-- Header -->
			<div class="p-4 pb-2">
				<SpButton
					:size="SpButtonSize.DefaultIconOnly"
					:type="SpButtonType.Tertiary"
					:icon-name="SpCommonIcon.Times"
					@click="emits('close')"
					class="ml-auto"
				></SpButton>
			</div>

			<!-- Content -->
			<div class="flex flex-col items-start p-10 pt-0 lg:flex-row lg:items-stretch lg:gap-10">
				<!-- Info -->
				<div class="order-last flex w-full flex-col gap-10 lg:order-first">
					<div class="flex flex-col gap-4 lg:gap-3">
						<SpText
							:size="SpTextSize.ProductiveLabel01"
							class="text-main-blue-400"
							:text="$t(TranslationKeys['make_a_wish_dialog.welcome.SUBTITLE'])"
						></SpText>
						<SpText
							:size="isMobile ? SpTextSize.HeadingLg01 : SpTextSize.HeadingXl01"
							class="text-main-blue-50"
							:text="$t(TranslationKeys['make_a_wish_dialog.welcome.TITLE'])"
						></SpText>

						<SpText
							:size="SpTextSize.BodyMd02"
							class="text-main-blue-50"
							:text="$t(TranslationKeys['make_a_wish_dialog.welcome.DESCRIPTION'])"
						></SpText>
					</div>

					<SpButton
						:size="SpButtonSize.Default"
						:type="SpButtonType.Tertiary"
						:text="$t(TranslationKeys['make_a_wish_dialog.welcome.MAKE_WISH_BUTTON'])"
						@click="emits('makeWish')"
					></SpButton>
				</div>

				<!-- SVG -->
				<div class="flex w-40 items-center justify-center lg:w-auto">
					<MakeWishSvgsChats />
				</div>
			</div>
		</div>

		<!-- Footer -->
		<div class="mt-14 flex w-full flex-col gap-6 px-6 pb-6 lg:px-10 lg:pb-10">
			<!-- Released wishes -->
			<div class="flex flex-col gap-4 lg:flex-row lg:items-center lg:gap-10">
				<div class="flex flex-col gap-4">
					<SpText
						:size="SpTextSize.HeadingLg01"
						:text="$t(TranslationKeys['make_a_wish_dialog.welcome.RELEASED_WISHES_TITLE'])"
						class="text-secondary-gray-900"
					></SpText>
					<SpText
						:size="SpTextSize.BodyMd02"
						class="text-secondary-gray-900"
						:text="$t(TranslationKeys['make_a_wish_dialog.welcome.RELEASED_WISHES_DESCRIPTION'])"
					></SpText>
				</div>

				<MakeWishSvgsReleasedWishes
					class="flex-shrink-0 flex-grow-0"
					:lang="currentLocale as 'en' | 'de' | 'it' | undefined"
				></MakeWishSvgsReleasedWishes>
			</div>

			<!-- Line separator -->
			<hr class="border-t-2 border-white bg-none" />

			<!-- Report a bug -->
			<div class="flex flex-col gap-1">
				<SpText
					:size="SpTextSize.BodyMd03"
					:text="$t(TranslationKeys['make_a_wish_dialog.welcome.BUG_REPORT_TITLE'])"
					class="text-secondary-gray-700"
				></SpText>
				<SpText
					:size="SpTextSize.BodyMd02"
					class="text-secondary-gray-700"
					:text="$t(TranslationKeys['make_a_wish_dialog.welcome.BUG_REPORT_DESCRIPTION'])"
				></SpText>
			</div>
		</div>
	</div>
</template>
