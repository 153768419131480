<template>
	<svg width="272" height="261" viewBox="0 0 272 261" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_dd_1285_2065)">
			<rect
				x="55.3013"
				y="175.269"
				width="161.557"
				height="49.1005"
				rx="8"
				transform="rotate(-15 55.3013 175.269)"
				fill="#36389B"
			/>
		</g>
		<g style="mix-blend-mode: screen" opacity="0.1">
			<path d="M76.2073 189.127L200.423 156.547" stroke="#36389B" stroke-width="8" stroke-linecap="round" />
			<path d="M79.759 202.669L143.146 186.268" stroke="#36389B" stroke-width="6" stroke-linecap="round" />
		</g>
		<g filter="url(#filter1_dd_1285_2065)">
			<rect
				x="44.1582"
				y="31.6676"
				width="169.722"
				height="51.5822"
				rx="8"
				transform="rotate(13.7492 44.1582 31.6676)"
				fill="#36389B"
			/>
		</g>
		<g style="mix-blend-mode: screen" opacity="0.1">
			<path d="M58.3792 57L183.117 87.5243" stroke="#36389B" stroke-width="8" stroke-linecap="round" />
			<path d="M55.0513 70.5988L118.597 86.3729" stroke="#36389B" stroke-width="6" stroke-linecap="round" />
		</g>
		<g filter="url(#filter2_dd_1285_2065)">
			<rect x="32" y="101.334" width="216" height="66" rx="12" fill="#3D3DBD" />
		</g>
		<g filter="url(#filter3_dd_1285_2065)">
			<rect
				x="92.2798"
				y="36.4786"
				width="138.003"
				height="41.9421"
				rx="6"
				transform="rotate(-12.2051 92.2798 36.4786)"
				fill="#36389B"
			/>
		</g>
		<g style="mix-blend-mode: screen" opacity="0.3">
			<path d="M111 48.6608L211.67 27" stroke="#36389B" stroke-width="8" stroke-linecap="round" />
			<path d="M113.945 62.3476L178 48.7875" stroke="#36389B" stroke-width="6" stroke-linecap="round" />
		</g>
		<g style="mix-blend-mode: screen" opacity="0.5">
			<path d="M53.9998 128.407L225.126 127" stroke="#3D3DBD" stroke-width="8" stroke-linecap="round" />
			<path d="M54.1147 142.407L119.589 142.086" stroke="#3D3DBD" stroke-width="6" stroke-linecap="round" />
		</g>
		<path
			d="M25.082 133.156C-2.27712 147.43 -26.8697 173.683 91.2458 151.067C239.601 122.661 263.023 80.841 211.135 87.8968"
			stroke="url(#paint0_linear_1285_2065)"
			stroke-width="4"
			stroke-linecap="round"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M38.2886 20.8714C37.3108 21.2218 36.8317 22.2875 37.1491 23.239C38.8743 28.4098 38.299 32.0247 37.0832 34.4386C35.9346 36.7193 34.1249 38.1081 32.702 38.7537C31.8553 39.1378 31.3264 40.1388 31.6818 41.1306L31.8922 41.7179C32.2438 42.6991 33.3077 43.182 34.2559 42.8658C39.4088 41.1475 43.0151 41.7325 45.4251 42.957C47.7021 44.114 49.0912 45.9317 49.7382 47.36C50.1232 48.2099 51.1227 48.7424 52.111 48.3883L52.6962 48.1786C53.6739 47.8282 54.1531 46.7625 53.8356 45.811C52.1104 40.6402 52.6858 37.0253 53.9016 34.6114C55.0502 32.3307 56.8599 30.942 58.2828 30.2964C59.1295 29.9122 59.6584 28.9112 59.303 27.9194L59.0925 27.3322C58.7409 26.3509 57.6771 25.8681 56.7289 26.1843C51.576 27.9026 47.9697 27.3175 45.5597 26.093C43.2826 24.9361 41.8936 23.1183 41.2465 21.69C40.8615 20.8402 39.862 20.3076 38.8738 20.6617L38.2886 20.8714Z"
			fill="url(#paint1_linear_1285_2065)"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M54.8487 10.9448C54.612 10.8518 54.3519 10.9711 54.2571 11.1978C53.742 12.4293 53.0534 13.0032 52.4402 13.2524C51.8609 13.4879 51.3034 13.4524 50.937 13.3421C50.7189 13.2765 50.4601 13.3759 50.3658 13.6161L50.31 13.7583C50.2167 13.9959 50.3351 14.2564 50.5609 14.3508C51.788 14.8643 52.3587 15.5533 52.6058 16.1675C52.8393 16.7478 52.8026 17.3068 52.6917 17.6744C52.6258 17.8932 52.7243 18.1525 52.9636 18.2464L53.1053 18.302C53.342 18.395 53.6021 18.2757 53.6969 18.0491C54.212 16.8175 54.9006 16.2436 55.5138 15.9944C56.0931 15.7589 56.6506 15.7945 57.017 15.9047C57.2351 15.9703 57.4939 15.8709 57.5882 15.6308L57.644 15.4886C57.7373 15.251 57.6189 14.9905 57.3931 14.896C56.166 14.3825 55.5953 13.6935 55.3481 13.0793C55.1147 12.4991 55.1514 11.94 55.2623 11.5724C55.3282 11.3536 55.2297 11.0944 54.9904 11.0004L54.8487 10.9448Z"
			fill="url(#paint2_linear_1285_2065)"
		/>
		<path
			opacity="0.6"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M223.778 61.8831C223.361 61.6071 222.812 61.7296 222.536 62.1265C221.04 64.2834 219.48 65.1041 218.198 65.3315C216.987 65.5464 215.929 65.249 215.27 64.8864C214.878 64.6707 214.339 64.7555 214.059 65.1786L213.893 65.4291C213.616 65.8478 213.737 66.3973 214.132 66.6718C216.281 68.1635 217.096 69.7231 217.319 71.0057C217.529 72.2176 217.229 73.2776 216.865 73.9391C216.648 74.3328 216.731 74.8718 217.153 75.1508L217.402 75.316C217.819 75.5921 218.369 75.4695 218.644 75.0726C220.141 72.9158 221.701 72.095 222.982 71.8676C224.193 71.6528 225.251 71.9501 225.91 72.3127C226.303 72.5284 226.841 72.4437 227.121 72.0205L227.287 71.77C227.564 71.3513 227.443 70.8018 227.048 70.5273C224.899 69.0356 224.084 67.4761 223.861 66.1934C223.651 64.9815 223.951 63.9215 224.315 63.26C224.532 62.8664 224.449 62.3273 224.028 62.0483L223.778 61.8831Z"
			fill="url(#paint3_linear_1285_2065)"
		/>
		<path
			opacity="0.8"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M55.1622 171.942C54.7202 172.081 54.4877 172.547 54.6125 172.977C55.2903 175.312 54.97 176.914 54.3854 177.968C53.8331 178.965 53.0019 179.552 52.3562 179.815C51.972 179.971 51.7185 180.408 51.8594 180.856L51.9429 181.122C52.0823 181.565 52.548 181.799 52.9763 181.675C55.3037 181 56.901 181.325 57.9538 181.913C58.9484 182.469 59.5355 183.304 59.7988 183.952C59.9554 184.338 60.3915 184.593 60.8384 184.452L61.1029 184.369C61.545 184.23 61.7774 183.763 61.6527 183.334C60.9749 180.998 61.2951 179.397 61.8797 178.342C62.432 177.346 63.2633 176.759 63.909 176.496C64.2932 176.34 64.5466 175.903 64.4057 175.454L64.3222 175.189C64.1828 174.745 63.7171 174.511 63.2888 174.635C60.9614 175.311 59.3641 174.986 58.3114 174.398C57.3167 173.842 56.7296 173.007 56.4664 172.359C56.3097 171.973 55.8736 171.718 55.4268 171.858L55.1622 171.942Z"
			fill="url(#paint4_linear_1285_2065)"
		/>
		<path
			opacity="0.3"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M233.295 171.294C232.979 171.169 232.631 171.329 232.504 171.632C231.815 173.279 230.895 174.046 230.075 174.379C229.3 174.694 228.555 174.647 228.065 174.499C227.773 174.412 227.427 174.545 227.301 174.866L227.226 175.056C227.102 175.374 227.26 175.722 227.562 175.848C229.203 176.535 229.966 177.456 230.296 178.277C230.608 179.053 230.559 179.801 230.411 180.292C230.323 180.585 230.455 180.932 230.775 181.057L230.964 181.132C231.281 181.256 231.628 181.096 231.755 180.793C232.444 179.147 233.365 178.379 234.185 178.046C234.959 177.731 235.705 177.778 236.195 177.926C236.486 178.014 236.832 177.881 236.958 177.56L237.033 177.369C237.158 177.052 237 176.703 236.698 176.577C235.057 175.891 234.294 174.969 233.963 174.148C233.651 173.372 233.7 172.625 233.848 172.133C233.937 171.84 233.805 171.494 233.485 171.368L233.295 171.294Z"
			fill="url(#paint5_linear_1285_2065)"
		/>
		<defs>
			<filter
				id="filter0_dd_1285_2065"
				x="33.3972"
				y="127.351"
				width="212.568"
				height="133.049"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="6.32" />
				<feGaussianBlur stdDeviation="4.74" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1285_2065" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="15.8" />
				<feGaussianBlur stdDeviation="11.85" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_1285_2065" result="effect2_dropShadow_1285_2065" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1285_2065" result="shape" />
			</filter>
			<filter
				id="filter1_dd_1285_2065"
				x="9.86885"
				y="25.4379"
				width="221.178"
				height="134.502"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="6.32" />
				<feGaussianBlur stdDeviation="4.74" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1285_2065" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="15.8" />
				<feGaussianBlur stdDeviation="11.85" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_1285_2065" result="effect2_dropShadow_1285_2065" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1285_2065" result="shape" />
			</filter>
			<filter
				id="filter2_dd_1285_2065"
				x="8.3"
				y="93.4342"
				width="263.4"
				height="113.4"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="6.32" />
				<feGaussianBlur stdDeviation="4.74" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1285_2065" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="15.8" />
				<feGaussianBlur stdDeviation="11.85" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_1285_2065" result="effect2_dropShadow_1285_2065" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1285_2065" result="shape" />
			</filter>
			<filter
				id="filter3_dd_1285_2065"
				x="69.7114"
				y="0.534845"
				width="188.888"
				height="115.306"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="6.32" />
				<feGaussianBlur stdDeviation="4.74" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1285_2065" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="15.8" />
				<feGaussianBlur stdDeviation="11.85" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
				<feBlend mode="normal" in2="effect1_dropShadow_1285_2065" result="effect2_dropShadow_1285_2065" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1285_2065" result="shape" />
			</filter>
			<linearGradient
				id="paint0_linear_1285_2065"
				x1="120.615"
				y1="83.7814"
				x2="118.025"
				y2="163.082"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#402D9E" />
				<stop offset="0.445" stop-color="#AEF0F3" />
				<stop offset="1" stop-color="#402D9E" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1285_2065"
				x1="40.3367"
				y1="20.1375"
				x2="50.6481"
				y2="48.9125"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#8A9BF0" />
				<stop offset="1" stop-color="#AEF0F3" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1285_2065"
				x1="55.3447"
				y1="11.1395"
				x2="52.6093"
				y2="18.1073"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#8A9BF0" />
				<stop offset="1" stop-color="#AEF0F3" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1285_2065"
				x1="224.652"
				y1="62.4613"
				x2="216.528"
				y2="74.7378"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#8A9BF0" />
				<stop offset="1" stop-color="#AEF0F3" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1285_2065"
				x1="56.0882"
				y1="171.651"
				x2="60.177"
				y2="184.66"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#8A9BF0" />
				<stop offset="1" stop-color="#AEF0F3" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_1285_2065"
				x1="233.959"
				y1="171.554"
				x2="230.301"
				y2="180.871"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#8A9BF0" />
				<stop offset="1" stop-color="#AEF0F3" />
			</linearGradient>
		</defs>
	</svg>
</template>
