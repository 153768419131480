<script setup lang="ts">
import { TrackingMessages } from '~/constants/trackingMessages'
import { MakeAWishNetworkObject } from '~/submodules/sharedTypes/communication/generic/MakeAWishNetworkObject'
const emits = defineEmits(['close', 'wishMade', 'backToWelcome'])

const userStore = useUserStore()
const { userData } = storeToRefs(userStore)

const subject = ref<string | undefined>(undefined)
const description = ref<string | undefined>(undefined)
const userInterview = ref(false)

const dataToSent = computed(() => ({
	subject: subject.value,
	description: description.value,
	email: userData.value.email,
}))

const onSendWish = async () => {
	await utilNetwork.simpleRequest(
		new MakeAWishNetworkObject({
			description: dataToSent.value.description ?? '',
			subject: dataToSent.value.subject,
			userInterview: !!userInterview.value,
		}),
		async () => {}
	)

	utilTracking.track(TrackingMessages.MAKE_A_WISH_SUBMIT, {})
	emits('wishMade')
}

onMounted(() => {
	utilTracking.track(TrackingMessages.MAKE_A_WISH_FORM_VIEW, {})
})
</script>

<template>
	<div>
		<div class="w-full">
			<!-- Header -->
			<div class="flex justify-between p-4 pb-2">
				<SpButton
					:size="SpButtonSize.DefaultIconOnly"
					:type="SpButtonType.Ghost"
					:icon-name="SpCommonIcon.ArrowLeft"
					@click="emits('backToWelcome')"
				></SpButton>
				<SpButton
					:size="SpButtonSize.DefaultIconOnly"
					:type="SpButtonType.Tertiary"
					:icon-name="SpCommonIcon.Times"
					class="ml-auto"
					@click="emits('close')"
				></SpButton>
			</div>

			<!-- Content -->
			<div class="w-full px-10 py-[49px]">
				<!-- Sent wish message -->
				<div class="flex w-full flex-col gap-10">
					<div class="flex w-full max-w-[512px] flex-col items-start gap-4">
						<SpText
							:size="SpTextSize.HeadingXl01"
							class="text-main-blue-600"
							:text="$t(TranslationKeys['make_a_wish_dialog.form.TITLE'])"
						></SpText>
						<SpText
							:size="SpTextSize.BodyMd02"
							class="text-secondary-gray-800"
							:text="$t(TranslationKeys['make_a_wish_dialog.form.DESCRIPTION'])"
						></SpText>

						<div class="flex gap-2">
							<SpText
								:size="SpTextSize.BodyMd03"
								class="text-secondary-gray-900"
								:text="$t(TranslationKeys['make_a_wish_dialog.form.BUG_REPORT'])"
							></SpText>
							<SpTooltip :text="$t(TranslationKeys['make_a_wish_dialog.form.BUG_REPORT_TOOLTIP'])" placement="bottom">
								<SpIcon
									:size="SpIconSize.Default"
									:name="SpCommonIcon.InfoCircle"
									class="fill-secondary-gray-900"
								></SpIcon>
							</SpTooltip>
						</div>
					</div>

					<div class="flex flex-col gap-4">
						<SpInputText
							class="max-w-[360px]"
							:label="$t(TranslationKeys['make_a_wish_dialog.form.SUBJECT_INPUT_LABEL'])"
							:placeholder="$t(TranslationKeys['make_a_wish_dialog.form.SUBJECT_INPUT_PLACEHOLDER'])"
							v-model="subject"
						></SpInputText>

						<SpTextArea
							:label="$t(TranslationKeys['make_a_wish_dialog.form.DESCRIBE_INPUT_LABEL'])"
							:placeholder="$t(TranslationKeys['make_a_wish_dialog.form.DESCRIBE_INPUT_PLACEHOLDER'])"
							:rows="5"
							v-model="description"
							:error-message="!description ? $t(TranslationKeys.REQUIRED_VALUE) : undefined"
						></SpTextArea>

						<SpCheckbox
							:label="$t(TranslationKeys['make_a_wish_dialog.form.CHECKBOX_INPUT_LABEL'])"
							v-model="userInterview"
							class="max-w-[516px]"
						></SpCheckbox>
					</div>

					<div class="items-bottom flex justify-end gap-2">
						<SpButton
							:size="SpButtonSize.Default"
							:type="SpButtonType.Secondary"
							:text="$t(TranslationKeys['make_a_wish_dialog.form.CANCEL_BUTTON'])"
							@click="emits('backToWelcome')"
						></SpButton>
						<SpButton
							:size="SpButtonSize.Default"
							:type="SpButtonType.Primary"
							:text="$t(TranslationKeys['make_a_wish_dialog.form.SEND_WISH_BUTTON'])"
							:is-disabled="!description"
							@click="onSendWish"
						></SpButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
