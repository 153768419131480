<script setup lang="ts">
import { TranslationKey } from '~/i18n/TranslationKeys';
import { TextSizes } from '~~/src/constants/textSizes'
import { UtilSales } from '~~/src/utils/UtilSales'

const isSalesMode = UtilSales.isSalesMode
const displayComponent = computed(() => UtilSales.canUseSalesMode() && isSalesMode.value)
</script>

<template>
	<div
		v-if="displayComponent"
		class="sticky flex items-center gap-2 px-4 py-2 ml-3 bg-main-blue-100 w-fit left-3 bottom-3 rounded-3xl shadow-high-contrast"
	>
		<div class="w-2 h-2">
			<div class="absolute w-2 h-2 rounded-full bg-main-blue-600" />
			<div class="absolute w-2 h-2 rounded-full bg-main-blue-600 animate-ping" />
		</div>
		<CommonText :text="'Demo mode' as TranslationKey" :text-size="TextSizes.PRODUCTIVE" class="text-main-blue-900" />
	</div>
</template>
